import { Typography } from '../../ui-lib';
import { Logo } from './SectionDetails';
import logo from '../../icons/logo.svg';
import React from 'react';

export const PoweredBy = () => {
  return (
    <>
      <Typography
        {...{
          variant: 'label',
          component: 'span',
          style: {
            color: '#9fa2b4',
          },
        }}
      >
        Powered by&nbsp;
      </Typography>
      <Logo {...{ src: logo }} />
    </>
  );
};
