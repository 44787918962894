import { computed, makeObservable, observable } from 'mobx';
import StoreBase from './StoreBase';
import { Charge, editPayment, getCheckoutChargesData, SubmitPaymentRequest } from '../api/api';
import { SetupIntent } from '@stripe/stripe-js';

export type PayStoreStatus = 'loading' | 'error' | 'loaded' | 'paymentError' | 'paid';

export type PayStoreErrors = 'paymentError' | 'errorFetching';
export type BillingSetting = 'auto' | 'invoice';

export default class PayStore extends StoreBase {
  public status: PayStoreStatus = 'loading';
  public error: PayStoreErrors | null = null;
  public charges: Charge[] = [];
  public billingSetting: BillingSetting = 'auto';

  constructor() {
    super();
    makeObservable(this, {
      // Observables
      status: observable,
      error: observable,
      charges: observable,
      billingSetting: observable,

      isAutoBilledWithCreditCard: computed,
      invoiceAutoBilled: computed,
      hasCharges: computed,
    });
  }

  public start = async () => {
    try {
      await this.rootStore.invoice.start();
      await this.getCheckoutChargesData();
      this.status = 'loaded';
    } catch (err) {
      console.error('err', err);
      this.rootStore.invoice.setStatus('error');
      this.rootStore.invoice.setError('errorFetching');
    }
  };

  public getCheckoutChargesData = async () => {
    if (!this.rootStore.checkout.checkoutToken) {
      return;
    }
    try {
      await this.getChargesData();
    } catch (err) {
      console.error('err', err);
    }
  };

  public async getChargesData() {
    const { data } = await getCheckoutChargesData(this.rootStore.checkout.checkoutToken);
    this.charges = data;
  }

  public savePaymentSettings = async (intent?: SetupIntent) => {
    const paymentRequest: SubmitPaymentRequest =
      this.billingSetting === 'auto' && intent
        ? {
            paymentGatewayId: intent.payment_method as string,
            gateway: 'stripe-cc',
            paymentTerm: 'IMMEDIATE',
            paymentTermNumericVal: 0,
            billedAutomatically: true,
          }
        : {
            billedAutomatically: false,
          };

    if (!this.rootStore.invoice.id && paymentRequest) {
      return;
    }
    try {
      await editPayment(this.rootStore.invoice.id, paymentRequest).then((result) => {
        this.status = 'paid';
        this.rootStore.invoice.start();
      });
      await this.getChargesData();
    } catch (err) {
      this.status = 'paymentError';
      console.error('err', err);
    }
  };

  public setSelectedBillingSetting = async (billingSetting: BillingSetting) => {
    this.billingSetting = billingSetting;
  };

  get hasCharges(): boolean {
    return Boolean(this.charges.length > 1);
  }

  get isAutoBilledWithCreditCard(): boolean {
    return Boolean(this.rootStore.invoice.invoiceData?.invoice?.autoBilled);
  }

  get invoiceAutoBilled(): boolean | null | undefined {
    return this.rootStore.invoice?.invoiceData?.invoice?.autoBilled;
  }
}
