import * as React from 'react';
import styled from '@emotion/styled';
import { Theme, theme } from '../../theme';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  SetupIntent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
import { stripePublicKey } from '../../config/config';
import FONT from './../../fonts/Aeonik-Regular.woff';
import { flexBetweenCenter } from '../../styles/common';
import greyLock from './../../icons/grey-lock.svg';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/setupContext';
import { ButtonWithIcon } from '../../sections/checkout/ButtonGroup/ButtonGroup';
import { SlashIcon } from '../../icons';
import InvoiceStore from '../../stores/InvoiceStore';
import CheckoutStore, { formatPrice } from '../../stores/CheckoutStore';

const stripePromise = loadStripe(stripePublicKey);

export interface CreditCardSplitFormProps {
  clientSecret: string;
  onSuccess: (setupIntent: SetupIntent) => void;
  onError: (error: StripeError) => void;
  setLoading: (flag: boolean) => void;
  showCardForm: boolean;
  setFormComplete: (flag?: boolean) => void;
  overrideNextButton: any;
  newCard: boolean;
  mode: 'payPage' | 'checkout' | 'paymentSettings';
  currency: CheckoutStore['currency'];
  currentCharge: InvoiceStore['currentCharge'];
}

export const Form = styled('form')<{ theme: Theme; showCardForm: boolean }>(({ theme, showCardForm }) => ({
  margin: 0,
  width: '100%',
  ...flexBetweenCenter,
  '& > div': {
    width: '100%',
  },
  ...{
    flexDirection: 'column',
    height: 100,
    marginTop: theme.gutters.base,
    '& button': {
      marginTop: theme.gutters.base,
    },
  },
  display: showCardForm ? 'flex' : 'none',
}));

export const CardElementsGrid = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: theme.gutters.base * 4,
  gridTemplateColumns: '1fr 1fr',
  gridTemplateAreas: `
      'number expiry'
      'postal_code cvc' 
      `,
}));

export const TextField = styled('input')<{ theme: Theme }>(({ theme }) => ({
  position: 'relative',
  padding: theme.gutters.base,
  border: 'none',
  outline: 'none',
  borderBottom: `1px solid ${theme.palette.blacks.eta}`,
  fontFamily: theme.typography.fontFamily,
  ...theme.typography.meta.caption,
}));

export const ZipCode = () => {
  const [value, setLocalPostalCode] = React.useState('');

  const handlePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^\d+$/;

    const isValid = regex.test(value);
    if (isValid) {
      setLocalPostalCode(value.slice(0, 5));
    }
  };
  return <TextField {...{ onChange: handlePostalCode, value }} />;
};

export const CardElementWrap = styled('div')<{ theme: Theme; gridArea: 'number' | 'expiry' | 'cvc' | 'postal_code' }>(
  ({ theme, gridArea }) => ({
    position: 'relative',
    padding: theme.gutters.base,
    borderBottom: `1px solid ${theme.palette.blacks.eta}`,
    gridArea,
    '& > img': {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  })
);

const stripeStyle = {
  base: {
    fontFamily: theme.typography.fontFamily,

    fontSize: `${theme.typography.meta.caption.fontSize}px`,
    color: theme.palette.blacks.alpha,

    '::placeholder': {
      color: theme.palette.background.gamma,
    },
  },
  invalid: {
    color: theme.palette.indicators.error,
  },
};

export const CreditCardSplitForm: React.FunctionComponent<CreditCardSplitFormProps> = ({
  mode,
  newCard,
  onError,
  onSuccess,
  setLoading,
  clientSecret,
  showCardForm,
  setFormComplete,
  overrideNextButton,
  currency,
  currentCharge,
}) => {
  const currencyFormatter = formatPrice(currency);
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [fieldsState, setFieldsState] = React.useState<
    Record<
      | StripeCardNumberElementChangeEvent['elementType']
      | StripeCardCvcElementChangeEvent['elementType']
      | StripeCardExpiryElementChangeEvent['elementType'],
      { complete?: boolean; error?: string }
    >
  >({ cardNumber: {}, cardExpiry: {}, cardCvc: {} });

  const [postalCode, setLocalPostalCode] = React.useState<string>('');

  React.useEffect(() => {
    fieldsState.cardNumber.complete === true &&
      fieldsState.cardExpiry.complete === true &&
      fieldsState.cardCvc.complete === true &&
      setFormComplete();

    return () => {
      setFormComplete(false);
    };
  }, [fieldsState]);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);

    e?.preventDefault?.();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.

      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      return;
    }

    setLoading(true);

    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: { card: cardNumberElement },
    });

    if (error) {
      console.error('[error]', error);
      setLoading(false);
      setIsSubmitting(false);
      onError(error);
      return;
    }

    setIsSubmitting(false);

    onSuccess(setupIntent!);
  };

  React.useEffect(() => {
    overrideNextButton({ cb: handleSubmit, text: newCard ? 'Next' : 'Add Card' });

    return () => {
      overrideNextButton(null);
    };
  }, [stripe, elements]);

  const handleCardChange = (
    event: StripeCardNumberElementChangeEvent | StripeCardCvcElementChangeEvent | StripeCardExpiryElementChangeEvent
  ) => {
    setFieldsState({
      ...fieldsState,
      [event.elementType]: {
        error: event.error,
        complete: event.complete,
      },
    });
  };

  const handlePostalCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^\d+$/;

    const isValid = regex.test(value);
    if (isValid) {
      setLocalPostalCode(value.slice(0, 5));
    }
  };

  return (
    <Form {...{ onSubmit: handleSubmit, showCardForm }}>
      <CardElementsGrid>
        <CardElementWrap {...{ gridArea: 'number' }}>
          <CardNumberElement
            {...{
              options: {
                style: stripeStyle,
              },

              onChange: handleCardChange,
            }}
          />
          <img {...{ src: greyLock }} />
        </CardElementWrap>
        <CardElementWrap {...{ gridArea: 'expiry' }}>
          <CardExpiryElement
            {...{
              options: { style: stripeStyle, placeholder: 'Exp date' },
              onChange: handleCardChange,
            }}
          />
        </CardElementWrap>

        <TextField
          {...{
            gridArea: 'postal_code',
            placeholder: 'Zip',
            value: postalCode,
            onChange: handlePostalCode,
          }}
        />
        <CardElementWrap {...{ gridArea: 'cvc' }}>
          <CardCvcElement
            {...{
              options: { style: stripeStyle },
              onChange: handleCardChange,
            }}
          />
        </CardElementWrap>
      </CardElementsGrid>
      {(mode === 'payPage' || mode === 'paymentSettings') && (
        <div style={{ marginTop: '2rem' }}>
          <ButtonWithIcon
            {...{
              fullWidth: true,
              disabled: false,
              leftIcon: <SlashIcon {...{ color: 'brand' }} />,
              style:
                mode === 'paymentSettings'
                  ? {
                      marginTop: 65,
                    }
                  : {},
            }}
          >
            <b>
              {mode === 'payPage'
                ? `Pay ${currentCharge ? currencyFormatter(String(currentCharge.amount)) : ''}`
                : 'Confirm'}
            </b>
          </ButtonWithIcon>
        </div>
      )}
    </Form>
  );
};

export type CardDetailsProps = CreditCardSplitFormProps & { generateClientSecret: () => void };

export const CardDetails: React.FunctionComponent<CardDetailsProps> = ({
  clientSecret,
  generateClientSecret,
  ...rest
}) => {
  React.useEffect(() => {
    // !clientSecret && generateClientSecret?.();
  }, [clientSecret]);

  if (!clientSecret) {
    return null;
  }

  return (
    <Elements
      {...{
        stripe: stripePromise,
        options: {
          fonts: [
            {
              family: '"Aeonik"',
              src: `url(${FONT})`,
            },
          ],
        },
      }}
    >
      <CreditCardSplitForm {...{ clientSecret, ...rest }} />
    </Elements>
  );
};

const Observed = observer(CardDetails);

const WithStoreConnection: React.FunctionComponent<Pick<CreditCardSplitFormProps, 'mode'>> = ({
  mode = 'checkout',
}) => {
  const { invoice, checkout, pay } = useStore();
  const {
    generateClientSecret,
    stripeClientSecret,
    creditCard,
    overrideNextButton,
    setLoading,
    handleStripeError,
    hasListOfCreditCards,
    currency,
  } = checkout;

  const {
    showCardForm,
    setFormComplete,
    onFreshlyCreatedCardSuccess,
    onAddingACardToExisitingCards,
    setShowCardForm,
  } = creditCard;
  const { payWithCreditCard, currentCharge } = invoice;
  const { savePaymentSettings } = pay;

  const onCardSuccesfullySaved = (() => {
    if (mode === 'checkout') {
      return hasListOfCreditCards ? onAddingACardToExisitingCards : onFreshlyCreatedCardSuccess;
    } else if (mode === 'payPage') {
      return payWithCreditCard;
    } else if (mode === 'paymentSettings') {
      return savePaymentSettings;
    }
  })();

  if (!onCardSuccesfullySaved) {
    return null;
  }

  return (
    <Observed
      {...{
        newCard: !hasListOfCreditCards,
        generateClientSecret,
        clientSecret: stripeClientSecret,
        showCardForm,
        setFormComplete,
        overrideNextButton,
        setLoading,
        setShowCardForm,
        onSuccess: onCardSuccesfullySaved,
        onError: handleStripeError,
        mode,
        currency,
        currentCharge,
      }}
    />
  );
};

export default observer(WithStoreConnection);
