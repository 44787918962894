import { PartialDeep } from 'type-fest';
import { Typography, typography } from './typography';

type MainPalette =
  | 'primary'
  | 'secondary'
  | 'brand'
  | 'disabled'
  | 'cta'
  | 'border'
  | 'current'
  | 'lightGrey'
  | 'gray'
  | 'purpleGray'
  | 'link'
  | 'linkOpaque'
  | 'strongGray';
type IndicatorsPalette = 'success' | 'warning' | 'error' | 'info' | 'danger' | 'successBold';
type TextColor = 'main' | 'contrast' | 'grey' | 'subtle' | 'link' | 'success';
type BackgroundPalette = 'site' | 'alpha' | 'beta' | 'gamma' | 'delta';
type BlacksPalette = 'alpha' | 'beta' | 'gamma' | 'delta' | 'epsilon' | 'zeta' | 'eta' | 'theta';
type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface Palette {
  main: Record<MainPalette, string>;
  indicators: Record<IndicatorsPalette, string>;
  text: Record<TextColor, string>;
  blacks: Record<BlacksPalette, string>;
  background: Record<BackgroundPalette, string>;
}

type ZIndexKeys = 'appBar' | 'drawer' | 'modal' | 'snackbar' | 'tooltip';

type Radius = 'button' | 'box' | 'card' | 'circle' | 'tooltip' | 'notifiaciton';

type Border = 'checkbox' | 'dashed';

type Opacity = 'hidden' | 'half' | 'visible';

/**
 * Basic theme we will use to build components from.
 */
export interface Theme {
  palette: Palette;
  zIndex: Record<ZIndexKeys, number>;
  gutters: Gutters;
  radius: Record<Radius, number>;
  border: Record<Border, string>;
  opacity: Record<Opacity, number>;
  typography: Typography;
  breakpoints: Record<Breakpoints, number>;
}

interface Gutters {
  base: number;
}

/**
 * Components Theme which has specific overrides for specific components.
 */
interface ComponentsTheme {}

type CreateThemeOptions = { theme?: PartialDeep<Theme> };

export const createTheme: (createThemeOptions?: CreateThemeOptions) => Theme = ({ theme } = {}) => ({
  palette: {
    main: {
      primary: '#3B6EDF',
      secondary: '#FBFFE6',
      brand: '#DCFF00',
      border: '#ccc',
      cta: 'green',
      disabled: '#333',
      current: 'currentColor',
      link: '#475dfc',
      linkOpaque: 'rgba(71, 93, 252, 0.1)',
      strongGray: '#60626E',
      ...theme?.palette?.main,
      lightGrey: '#E3E4ED',
      gray: '#9FA2B4',
      purpleGray: '#f4f4fa',
    },
    indicators: {
      success: '#D3E8C8',
      successBold: '#B3FAD1',
      warning: '#FAECC7',
      error: '#FAC7B7',
      info: '#BED6EE',
      danger: '#DB0C0C',
      ...theme?.palette?.indicators,
    },
    text: {
      main: '#1D1D1D',
      contrast: '#fff',
      grey: '#989898',
      subtle: '#EAEBEC',
      link: '#475DFC',
      success: '#47F1D1',
    },
    blacks: {
      alpha: '#1D1D1D',
      beta: '#242424',
      gamma: '#666666',
      delta: '#3A3A3A',
      epsilon: '#B4B4B4',
      zeta: '#CCCCCC',
      eta: '#E5E5E5',
      theta: '#FFFFFF',
    },
    background: {
      site: '#fff',
      alpha: '#F3F3F3',
      beta: '#EBF2FF',
      gamma: '#666',
      /** Hasn't been defined */
      delta: '#f0f0f0',
      ...theme?.palette?.background,
    },
  },
  zIndex: {
    appBar: 600,
    modal: 700,
    drawer: 800,
    snackbar: 900,
    tooltip: 1000,
    ...theme?.zIndex,
  },
  border: {
    checkbox: '1px solid #ccc',
    dashed: '1px dashed #ccc',
    ...theme?.border,
  },
  opacity: {
    hidden: 0,
    half: 0.5,
    visible: 1,
    ...theme?.opacity,
  },
  gutters: { base: 8, ...theme?.gutters },
  radius: {
    button: 20,
    card: 8,
    circle: 50,
    tooltip: 0,
    notifiaciton: 4,
    box: 6,
    ...theme?.radius,
  },
  typography: { ...typography() },
  breakpoints: { xs: 0, sm: 550, md: 960, lg: 1280, xl: 1920 },
});

export const theme = createTheme();
