import StoreBase from './StoreBase';
import { getUrlSearchParams } from '../utils/route.utils';
import { makeObservable, observable, toJS } from 'mobx';
import { PlaidMetadata, PlaidSuccessResponse, Token } from '../types/Plaid';
import { persistPlaid, setToken, login, getBuyerQualification, BuyerQualification } from '../api/api';

export type QualificationStatus = 'loading' | 'open' | 'started' | 'completed' | 'expired' | 'error';
export type QualificationStep = 'linkBank' | 'success';

export default class QualificationStore extends StoreBase {
  public status: QualificationStatus = 'loading';
  public step: QualificationStep = 'linkBank';
  public qualificationToken: string | null = '';
  public bankLinkToken: string | null = '';
  public buyerQualification: BuyerQualification | null = {} as BuyerQualification;

  constructor() {
    super();
    makeObservable(this, {
      status: observable,
      step: observable,
    });
  }

  public login = async () => {
    this.status = 'loading';
    if (!this.qualificationToken) {
      return;
    }
    try {
      const { data } = await login({
        qualificationKey: this.qualificationToken,
        emailAddress: null,
        retryCode: false,
        smsCode: false,
      });

      setToken(data.accessToken);
    } catch (err) {
      this.status = 'expired';
      console.error('err', err);
      throw err;
    }
  };

  private getBuyerQualification = async () => {
    const qualification = await getBuyerQualification();
    this.buyerQualification = qualification.data;
    this.status = this.buyerQualification.status;
  };

  public fetchQualification = async () => {
    try {
      const queryParams = getUrlSearchParams();
      this.qualificationToken = queryParams.get('qid');
      await this.login()
        .then(this.getBuyerQualification)
        .catch((error) => {
          this.status = 'expired';
        });
    } catch (err) {
      this.status = 'error';
      console.error('err', err);
    }
  };

  public onBankLinkSuccess = async (token: Token, metadata: PlaidMetadata) => {
    await this.persistPlaid({
      token,
      metadata,
    });
    this.step = 'success';
  };

  private persistPlaid = async (plaidResponse: PlaidSuccessResponse) => {
    if (!plaidResponse) {
      return;
    }
    try {
      await persistPlaid({
        publicToken: plaidResponse.token,
        meta: toJS(plaidResponse.metadata),
        accountId: plaidResponse.metadata.account_id,
        isQualificationAccount: true,
      });
    } catch (err) {
      console.error('err', err);
      this.status = 'error';
    }
  };

  public onBankLinkExit = async () => {
    this.status = 'error';
  };

  public tryAgain = async () => {
    this.start();
  };

  public start = async () => {
    try {
      this.status = 'started';
      this.step = 'linkBank';
    } catch (err) {
      console.error('err', err);
    }
  };
}
