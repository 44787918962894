import { PlaidMetadata } from '../types/Plaid';
import axios, { AxiosResponse } from 'axios';
import { apiBaseURL } from '../config/config';
import { QualificationStatus } from '../stores/QualificationStore';

// ! BEWARE - Most of the interface here are not complete.
// ! Don't blindly rely on them.

const api = axios.create({
  baseURL: `${apiBaseURL}/api/v1`,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    common: {
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOm51bGwsImJpZCI6MTEsImVtYWlsIjoiYW1vdW50XzIyMjBAZXhhbXBsZS5jb20iLCJyb2xlIjoyNDgsImlhdCI6MTYwMDg1Njg1MiwiZXhwIjoxNjAwOTQzMjUyfQ.lF9yv218QKlBpbLDfFmM60Pq3A216nIAKDT4Qbk0BP4',
    },
  },
});

export const setToken = (token: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

interface PersistPlaidRequest {
  publicToken: string;
  accountId: string;
  meta: any;
  isQualificationAccount?: boolean;
}

export type PersistPlaidResponse = Array<{
  itemId: string;
  institution: PlaidMetadata['institution'];
  account: PlaidMetadata['accounts'][0];
}>;

export const persistPlaid = async (payload: PersistPlaidRequest) => {
  return api.post<PersistPlaidResponse>('/payment/bank-transfer/plaid/persist', payload);
};

export type GetBankAccountsResponse = Array<{
  itemId: string;
  account: {
    id: string;
    name: string;
    type: string;
    subtype: string;
    mask: string;
  };
  institution: { name: string; institution_id: string };
}>;

export const getBankAccounts = async () => {
  return api.get<GetBankAccountsResponse>('/payment/bank-transfer/plaid/accounts');
};

export interface DeleteBankAccountResponse {
  itemId: string;
}

export const deleteBankAccount = async (accountId: string) => {
  return api.delete<DeleteBankAccountResponse>(`/payment/bank-transfer/plaid/accounts/${accountId}`);
};

export interface SetWireTransferResponse {
  id: string;
  accountNum: string;
  routingNum: string;
  bankName: string;
  swiftCode: string;
}

export const setWireTransfer = async () => {
  return api.post<SetWireTransferResponse>('/payment/bank-transfer/stipe-credit-transfer');
};

export const generateClientSecret = async () => {
  return api.post<{ data: { clientSecret: string } }>(`/payment/cc/stripe/setup-intent`);
};

export interface SubmitPaymentResponse {
  transactionId: string;
  status: 'success';
}

export interface SubmitPaymentRequest {
  /**
   * stripe-ach stands for bankTransfer (plaid)
   * stripe-cc stands for creditCard (stripe)
   * stripe-ach-credit-transfer stands for wireTransfer
   */
  gateway?: 'stripe-ach' | 'stripe-cc' | 'stripe-ach-credit-transfer' | undefined;
  paymentGatewayId?: string | undefined;
  /**
   *
   */
  paymentTermNumericVal?: number | undefined;
  depositPaymentId?: string | undefined;
  paymentTerm?: PaymentTermType | undefined;
  billedAutomatically?: boolean | undefined;
}

export const submitPayment = async (checkoutToken: string, payload: SubmitPaymentRequest) => {
  return api.post<GetCheckoutDataResponse['checkout']>(`/checkout/${checkoutToken}/payment`, payload);
};

export const editPayment = async (invoiceId: string, payload: SubmitPaymentRequest, chargeId?: string) => {
  if (chargeId) {
    return api.post(`/charges/${chargeId}/payment`, payload);
  }
  return api.post(`/invoice/${invoiceId}/payment`, payload);
};

interface SubmitPayLaterRequest {
  plaidItemId: string;
}

export interface SubmitPayLaterResponse {
  status: 'FULL' | 'PARTIAL' | 'NOT_APPROVED' | 'FAILED';
  terms?: {
    agreement: string;
    days: number;
    dueDate: string;
    feeAmount: number;
    feePercent: number;
  };
}

export const submitPayLater = async (chekcoutToken: string, payload: SubmitPayLaterRequest) => {
  return api.post<SubmitPayLaterResponse>(`/checkout/${chekcoutToken}/pay-later`, payload);
};

export const generateLinkTokenForPlaid = async (mode?: string) => {
  return api.get<{ linkToken: string }>(`/payment/bank-transfer/plaid/link-token${mode ? `?mode=${mode}` : ''}`);
};

export interface SubmitElectronicQuoteRequest {
  message?: string;
  checkoutToken: string;
}

export interface SubmitElectronicQuoteResponse {}

export const submitElectroniceQuote = async (payload: SubmitElectronicQuoteRequest) => {
  return api.post<SubmitElectronicQuoteResponse>(`/quote`, payload);
};

export interface GetListOfStripeCardsResponse {
  data: {
    existingMethods: Array<{
      id: string;
      object: string;

      billing_details: {
        email: string | null;
        name: string | null;
        phone: string | null;
        address: {
          city: string | null;
          country: string | null;
          line1: string | null;
          line2: string | null;
          postal_code: string | null;
          state: string | null;
        };
      };
      created: number;
      customer: string;
      livemode: boolean;
      metadata: object;
      type: string;
      card: {
        brand: string;
        checks: {
          address_line1_check: string | null;
          address_postal_code_check: string | null;
          cvc_check: string | null;
        };
        /** Two digit */
        country: string | null;
        exp_month: number;
        exp_year: number;
        fingerprint: string;
        funding: string;
        generated_from: string | null;
        last4: string;
        networks: { available: string[]; preferred: null };
        three_d_secure_usage: { supported: true };
        wallet: null;
      };
    }>;
  };
  stripepk: string;
}

export const getListOfStripeCards = () => {
  return api.get<GetListOfStripeCardsResponse>(`/payment/cc/stripe/cards`);
};

export interface DeleteCreditCardResponse {
  cardId: string;
}

export const deleteCreditCard = async (id: string) => {
  return api.delete<DeleteCreditCardResponse>(`/payment/cc/stripe/cards/${id}`);
};

export interface LoginPayload {
  checkoutKey?: string;
  qualificationKey?: string;
  emailAddress: string | null;
  retryCode: boolean;
  smsCode: boolean;
}

export interface LoginResponse {
  accessToken: string;
}

export const login = async (payload: LoginPayload) => {
  return api.post<LoginResponse>(`/customers/auth/login`, payload);
};

export interface BuyerCheckoutSchema {
  id: string | number;
  email: string;
  firstName?: string;
  lastName?: string;
  businessName: string;
  phone?: string;
}

export interface AddressSchema {
  firstName?: string;
  lastName?: string;
  streetAddress1?: string;
  streetAddress2?: string | null;
  countryCode?: string;
  state?: string | null;
  zipCode?: string;
  city?: string;
}

export interface LineItemsSchema {
  id: number;
  title: string;
  quantity: number;
  productId: string;
  productSku: string;
  variationId: string;
  itemType: string;
  price: string;
}

export interface TransactionRetrieveSchema {
  lineItems?: LineItemsSchema[];
  tax: string;
  shippingPrice: string;
  totalLineItems: string;
  merchant?: string;
  // Need to verify, based on a response. Could have a structure.
  seller?: string;
}

export type PaymentTermType = 'IMMEDIATE' | 'NET';

export type FeatureType = 'invite_checkout' | 'registration';

export interface LeanPaymentTermSchema {
  termType: PaymentTermType;
  termNumericaVal?: number;
  termNumericaVal2?: number;
}

export interface MilestoneRequest {
  name?: string;
  chargeDate?: Date;
  amount: number;
  type: MilestoneType;
  note?: string;
}

export type ChargeStatus = 'pending' | 'charged' | 'failed' | 'waitingForPayment';

export interface Charge {
  id?: string;
  note?: string;
  name?: string;
  chargeDate?: string;
  amount: number;
  currency: string;
  buyerId: number;
  type: string;
  status: ChargeStatus;
  paymentMethodType?: string;
  processingFee?: number;
  invoiceToken: string;
  invoicePdfLink: string;
}

export type RecurringTimeUnit = 'monthly' | 'yearly';

export interface RecurringPayment {
  timeUnit: RecurringTimeUnit;
  numberOfTotalRepeats?: number;
  startDate?: string;
}

export type MilestoneType = 'time' | 'event';

export interface GetCheckoutDataResponse {
  checkout: {
    currency: string;
    cartToken: string;
    installments: number;
    milestones?: MilestoneRequest[];
    recurringPayment?: RecurringPayment;
    buyer: BuyerCheckoutSchema;
    totalShipping: string;
    totalLineItems: string;
    totalTax: string;
    totalPrice: string;
    billingAddress: AddressSchema;
    shippingAddress: AddressSchema | null;
    transactions: TransactionRetrieveSchema[];
    created: Date | string;
    updated: Date | string;
    chargeDate: Date | null;
    merchantDomain: string;
    merchantName: string;
    status: 'open' | 'closed' | 'pending';
    financialStatus: string;
    paymentTerm?: LeanPaymentTermSchema;
    qualification?: boolean;
  };
  flags: any[];
  installmentsTerms: {
    termsPerInstallment: string;
    restPerInstallment: string;
    totalRest: number;
    totalTerms: number;
  };
}

export const getCheckoutData = async (checkoutToken: string) => {
  return api.get<GetCheckoutDataResponse>(`/checkout/${checkoutToken}`);
};

export const getCheckoutChargesData = async (checkoutToken: string): Promise<AxiosResponse<Charge[]>> => {
  return api.get<Charge[]>(`/checkouts/${checkoutToken}/charges`);
};

export interface BankTransfer {
  accountNum: string;
  routingNum: string;
  bankName: string;
  swiftCode: string;
}

export type InvoiceStatus = 'draft' | 'open' | 'void' | 'paid' | 'uncollectible';

export interface GetInvoiceDataResponse {
  invoice: {
    id: string;
    token: string;
    bankTransfer: BankTransfer;
    status: InvoiceStatus;
    sellerName: string;
    buyerName: string;
    prettyToken: string;
    pdfLink: string;
    sellerLogo: string;
    createdAt: string;
    chargeDate: string;
    instructions: { instructions: string; mailAddress: string };
    autoBilled: boolean;
  };
}

export const getInvoiceData = async (invoiceToken: string) => {
  return api.get<GetInvoiceDataResponse>(`/invoice/${invoiceToken}`);
};

export type PaymentMethodName = 'creditCard' | 'bank' | 'check' | 'payWithTerms' | 'invoice';

export interface PaymentMethod {
  allowedPaymentMethods: Array<{
    name: PaymentMethodName;
    days: number[];
  }>;
}

export type UserStatus = 'ACTIVE' | 'INACTIVE' | 'DELETED' | 'SUSPENDED';

export interface User {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  status: UserStatus;
  phone: string;
  createdAt: Date;
  updatedAt: Date;
  business: Business;
  businessId: number;
  userScopes: number;
}

export interface Business {
  id: number;
  name: string;
  domain: string;
  address: AddressSchema;
  ein: string;
  users: User[];
  createdAt: Date;
  updatedAt: Date;
  sellers: Seller[];
  buyers: Seller[];
}

export interface Buyer {
  id: number;
  business: Business;
  name: string;
  isGuest: boolean;
  guestEmail: string;
  guestFirstName: string;
  guestLastName: string;
  guestPhone: string;
  createdAt: Date;
  updatedAt: Date;
}

export type SellerStatus = 'pending' | 'active' | 'inactive' | 'deleted' | 'suspended';

export interface Seller {
  id: number;
  name: string;
  domain: string;
  status: SellerStatus;
  business: Business;
  contactUser: User;
  createdAt: Date;
  updatedAt: Date;
  parent: Seller;
  parentId: number;
  foreignMerchantId: string;
  blnceTransferFee: TransferFee;
  parentTransferFee: TransferFee;
  buyers: Buyer[];
  sellerLogo: string;
}

export type FeeModel = 'percentage' | 'fixed' | 'lower_percentage_fixed';

export interface TransferFee {
  id: number;
  name: string;
  percentage: number;
  fixedAmount: number;
  model: FeeModel;
  createdAt: Date;
  updatedAt: Date;
}

export interface PaymentTermsPolicy {
  id: number;
  term: PaymentTermType;
  customer?: Buyer;
}

export interface Merchant {
  name: string;
  logo: string;
  businessDomain: string;
}

export interface BuyerQualification {
  status: QualificationStatus;
  buyerId: number | string;
  merchant: Merchant;
}

export type GetPaymentMethodsResponse = PaymentMethod;

export const getPaymentMethods = async (checkoutToken: string) => {
  return api.get<GetPaymentMethodsResponse>(`/checkout/${checkoutToken}/payment-methods`);
};

export const getBuyerQualification = async (): Promise<AxiosResponse<BuyerQualification>> => {
  return api.get<BuyerQualification>('/qualification');
};

//@ts-ignore
window.api = {
  login,
  getCheckoutData,
  getPaymentMethods,
};

export interface SubmitInvoiceRequest {
  dueInDays: number;
  onBehalfSubSeller: false;
  paymentTerm: PaymentTermType;
}

export interface SubmitInvoiceResponse {
  url: string;
}

export const submitInvoice = async (checkoutToken: string, payload: SubmitInvoiceRequest) => {
  return api.post<SubmitInvoiceResponse>(`/invoice/checkout/${checkoutToken}`, payload);
};
