import * as React from 'react';
import Label from '../Label/Label';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { useRef } from 'react';

export interface RadioProps {
  checked: React.HTMLAttributes<HTMLInputElement['checked']>;
  name: string;
  onClicked: (value: any | undefined) => void;
  value: React.InputHTMLAttributes<HTMLInputElement>['value'];
  label?: string;
  childComponent?: React.ReactNode;
}

export const RadioContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'flex-start',
  cursor: 'pointer',
}));

export const RadioSibling = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginLeft: theme.gutters.base * 2,
  marginTop: theme.gutters.base,
}));

export const RadioInput = styled('input')<{ theme: Theme }>(({ theme }) => ({
  position: 'relative',
  visibility: 'hidden',
  '&:checked ~ div label': {
    color: `${theme.palette.text.link} !important`,
  },
  '&:after': {
    content: '""',
    width: 16,
    height: 16,
    border: `2px solid ${theme.palette.main.strongGray}`,
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    visibility: 'visible',
  },
  '&:checked:after': {
    width: 20,
    height: 20,
    border: `0px`,
    backgroundColor: theme.palette.text.link,
  },
}));
export const RadioInputWrapper = styled('div')<{ theme: Theme }>(({ theme }) => ({
  position: 'relative',
  visibility: 'hidden',
  '&:after': {
    content: '""',
    width: 10,
    height: 10,
    border: `3px solid white`,
    borderRadius: '50%',
    position: 'absolute',
    top: 2,
    right: 0,
    bottom: 0,
    left: 2,
    visibility: 'visible',
  },
}));

export const Radio: React.FunctionComponent<RadioProps> = ({
  checked,
  onClicked,
  value,
  label,
  childComponent,
  name,
}) => {
  const radioInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent) => {
    onClicked(radioInputRef.current?.value);
  };

  return (
    <RadioContainer {...{ onClick: handleClick }}>
      <RadioInputWrapper>
        <RadioInput
          {...{
            type: 'radio',
            name,
            onClick: handleClick,
            value,
            checked: Boolean(checked),
          }}
          ref={radioInputRef}
        />
      </RadioInputWrapper>
      <RadioSibling>
        {label && <Label variant={'xl'} {...{ label, style: checked ? { color: '#475DFC' } : {} }} />}
        {childComponent}
      </RadioSibling>
    </RadioContainer>
  );
};
