import * as React from 'react';
import { observer } from 'mobx-react';

import { useStore } from '../../stores/setupContext';
import InvoiceLayout from './InvoiceLayout/InvoiceLayout';

export interface InvoiceManagerProps {
  isMissingInvoice: boolean;
}

export const InvoiceManager: React.FC<InvoiceManagerProps> = ({}) => {
  return <InvoiceLayout />;
};

const Observed = observer(InvoiceManager);

const WithStoreConnection = () => {
  const { invoice, checkout } = useStore();

  React.useEffect(() => {
    invoice.start();
    checkout.start();
  }, []);

  return <Observed isMissingInvoice={invoice.isUnableToFindInvoice} />;
};

export default observer(WithStoreConnection);
