import { PartialDeep } from 'type-fest';
import { RootStore } from './RootStore';

import CheckoutStore from './CheckoutStore';
import InvoiceStore from './InvoiceStore';
import PayStore from './PayStore';
import QualificationStore from './QualificationStore';

// import ErrorsStore from './errors';
// import PopupsStore from './popup';

export interface Stores {
  checkout: CheckoutStore;
  invoice: InvoiceStore;
  pay: PayStore;
  qualification: QualificationStore;
}

export type DeepParitalStores = PartialDeep<Stores>;

export const createStore = (externalStores?: DeepParitalStores) => {
  return new RootStore<Stores>({
    checkout: new CheckoutStore(),
    invoice: new InvoiceStore(),
    pay: new PayStore(),
    qualification: new QualificationStore(),
  });
};

// ? Dashboard - each page gets a store
