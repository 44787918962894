import styled from '@emotion/styled';
import { Theme } from '../../../theme';
import { flexCenterCenter, flexCenterStretch } from '../../../styles/common';

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.main.purpleGray,
}));

export const Content = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  padding: `${theme.gutters.base * 2}px`,
  maxWidth: 1000,
  [`@media (min-width: ${theme.breakpoints.md}px)`]: {
    ...flexCenterStretch,
    margin: `${theme.gutters.base * 2}px auto`,
  },
}));

export const Footer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.gutters.base,
}));

export const Box = styled('div')<{ theme: Theme; maxHalfSize?: boolean; isMiddleBox?: boolean }>(
  ({ theme, maxHalfSize, isMiddleBox }) => {
    const mediaQuery = `@media (min-width: ${theme.breakpoints.md}px)`;
    const border = `1px solid ${theme.palette.main.border}`;

    return {
      paddingTop: theme.gutters.base * 4,
      paddingBottom: theme.gutters.base * 4,
      paddingLeft: theme.gutters.base,
      paddingRight: theme.gutters.base,
      border,
      borderTop: isMiddleBox ? 'none' : undefined,
      [mediaQuery]: {
        paddingLeft: theme.gutters.base * 4,
        paddingRight: theme.gutters.base * 4,
        borderTop: border,
        borderLeft: isMiddleBox ? 'none' : undefined,
        flex: isMiddleBox ? '1 1 70%' : '',
        maxWidth: maxHalfSize ? '50%' : undefined,
      },
    };
  }
);

export const LoaderContainer = styled('div')({
  ...flexCenterCenter,
  minHeight: '100%',
  width: '100%',
});

export const NotFoundContainer = styled('div')({
  height: 300,
});
