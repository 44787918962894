import * as React from 'react';
import { PoweredBy } from './PoweredBy';

export const PaymentFooter = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-3.8rem',
      }}
    >
      <PoweredBy />
    </div>
  );
};
